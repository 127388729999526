
























import {Component, Prop, Vue} from "vue-property-decorator";
import {MaintenanceElementStatus, MaintenanceListElement} from "@/models/maintenanceElement";

@Component({
  components: {
    MaintenanceListRadio: () => import('./../components/radio/MaintenanceListRadio.component.vue'),
  }
})
export default class MaintenanceListTableRowComponent extends Vue {

  @Prop({default: () => {}})
  public maintenanceListElement!: MaintenanceListElement;

  public elementChanged(value: MaintenanceElementStatus) {
    this.maintenanceListElement.controlStatus = value;
  }
}
